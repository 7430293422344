import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { Page404Component } from "./extrapages/page404/page404.component";
import { HorizontalComponent } from "./layouts/horizontal/horizontal.component";
import { VerticalComponent } from "./layouts/vertical/vertical.component";

const routes: Routes = [
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  // tslint:disable-next-line: max-line-length
  {
    path: "",
    component: HorizontalComponent,
    loadChildren: () =>
      import("./casadocontista/casadocontista.module").then(
        (m) => m.CasaDoContistaModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: "private",
    component: VerticalComponent,
    loadChildren: () =>
      import("./pages/admcasadocontista/admcasadocontista.module").then(
        (m) => m.AdmCasaDoContistaModule
      ),
    // canActivate: [AuthGuard],
  },
  // {
  //   path: "",
  //   component: LayoutComponent,
  //   loadChildren: () =>
  //     import("./pages/pages.module").then((m) => m.PagesModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "pages",
  //   loadChildren: () =>
  //     import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule),
  //   canActivate: [AuthGuard],
  // },
  // { path: "crypto-ico-landing", component: CyptolandingComponent },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
